import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container pt-4 mb-4">
            <div className="row">
                <div className="col-12">
                    <h4 className="hd__txt">Privacy Policy</h4>
                    <p className='privacy__txt'>
                        At our tech company, we take your privacy seriously. This privacy policy explains how we collect, use, and
                        protect your personal information when you use our website. By using our website, you agree to the terms of this privacy policy.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">Information We Collect</h4>
                    <p className='privacy__txt'>
                        When you visit our website, we automatically collect certain information about your device, including information about your web browser,
                        IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse our website, we collect
                        information about the individual web pages or products that you view, what websites or search terms referred you to our website, and
                        information about how you interact with our website. We also collect personal information that you voluntarily provide to us, such as \
                        your name, email address, and any other information you choose to provide when filling out a form or making a purchase.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">How We Use Your Information</h4>
                    <p className='privacy__txt'>
                        We use the information we collect to provide and improve our website and services, to communicate with you, to process your transactions,
                        and to personalize your experience on our website. We may also use your information to:
                    </p>
                    <ul style={{ listStyleType: "circle" }} className="privacy__txt__list">
                        <li>Provide you with marketing or promotional communications that may be of interest to you;</li>
                        <li>Respond to your customer service inquiries and support needs;</li>
                        <li>Administer surveys or other promotional activities; and</li>
                        <li>Comply with applicable laws and regulations.</li>
                    </ul>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">How We Protect Your Information</h4>
                    <p className='privacy__txt'>
                        We take appropriate technical and organizational measures to protect your personal information from unauthorized access,
                        disclosure, alteration, and destruction. We use industry-standard encryption and other security measures to protect the
                        confidentiality of your personal information.
                        However, no method of transmission over the Internet or method of electronic storage is 100% secure.
                        Therefore, we cannot guarantee the absolute security of your information.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">Sharing Your Information</h4>
                    <p className='privacy__txt'>
                        We may share your personal information with third-party service providers who perform services on our behalf, such as
                        payment processing, data analysis, email delivery, hosting services, customer service, and marketing. These third-party
                        service providers are only allowed to use your personal information to perform these services on our behalf and are
                        contractually obligated to keep your information confidential.
                        We may also disclose your personal information to comply with applicable laws and regulations, to respond to a subpoena,
                        search warrant, or other lawful request for information we receive, or to otherwise protect our rights.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">Your Choices</h4>
                    <p className='privacy__txt'>
                        You have the right to access, correct, update, or delete the personal information we have collected about you. You can also opt-out of
                        receiving marketing or promotional communications from us by following the instructions in those communications.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">Children’s Privacy</h4>
                    <p className='privacy__txt'>
                        Our website is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the
                        age of 13. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete
                        the information as soon as possible.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">Changes to This Privacy Policy</h4>
                    <p className='privacy__txt'>
                        We reserve the right to modify this privacy policy at any time. If we make material changes to this policy, we will notify you by updating the date
                        at the top of this page or by sending you an email notification.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">Contact Us</h4>
                    <p className='privacy__txt'>
                        If you have any questions or concerns about this privacy policy or our privacy practices, please contact us.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;