import React, { useState } from 'react';
import SocialIcons from './../social_Icons/SocialIcons';
import web_dev_img from '../../Images/web_dev_img.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import GetQuoteButton from '../main_home_page/GetQuoteButton';


import javascript from '../../Images/javascript.png';
import nodejs from '../../Images/nodejs.png';
import react from '../../Images/react.png';
import angular from '../../Images/angular.png';
import php from '../../Images/php.png';
import laravel from '../../Images/laravel.png';
import mysql from '../../Images/mysql.png';
import fluter from '../../Images/fluter.png';
import Desk from '../../Images/Desk.png';
import web___ux from '../../Images/web___ux.png';
import npoly_logo from '../../Images/npoly_logo.png';
import AyneerGurucul from '../../Images/AyneerGurucul.png';
import BCLC_img from '../../Images/BCLC_img.png';
import it_hosting_serv_support from '../../Images/it_hosting_serv_support.png';
import ITAuditConsultancyBannerup from '../../Images/ITAuditConsultancyBannerup.jpg';
import ITAudit from '../../Images/ITAudit.png';
import ITConsaltancy from '../../Images/ITConsaltancy.png';
import Hostingservicesuport from '../../Images/Hostingservicesuport.png';
import ITservicesupport from '../../Images/ITservicesupport.png';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


const ITAuditConsultancy = () => {
    const ITTechFrameworksSlider = {
        // className: "center mx-4",
        // infinite: true,
        // centerPadding: "60px",
        // slidesToShow: 5,
        // swipeToSlide: true,
        // adaptiveHeight: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
        // or 
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [itTechFrameworks, setITTechFrameworks] = useState([
        { technologies_name: "JavaScript", technologies_img: javascript, id: 1 },
        { technologies_name: "Node.js", technologies_img: nodejs, id: 2 },
        { technologies_name: "React.js", technologies_img: react, id: 3 },
        { technologies_name: "Angular.js", technologies_img: angular, id: 4 },
        { technologies_name: "PHP", technologies_img: php, id: 5 },
        { technologies_name: "Laravel", technologies_img: laravel, id: 6 },
        { technologies_name: "MySql", technologies_img: mysql, id: 7 },
        { technologies_name: "Fluter", technologies_img: fluter, id: 8 },
        { technologies_name: "PHP", technologies_img: php, id: 9 },

    ]);
    const [ourRecentITProjects, setOurRecentITProjects] = useState([
        { projects_name: "Clinical Management System", id: 1 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 2 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 3 },
        { projects_name: "Automated Legal Contracts Generation", id: 4 },
        { projects_name: "Clinical Management System", id: 5 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 6 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 7 },
        { projects_name: "Automated Legal Contracts Generation", id: 8 },
    ]);
    const [ourRecentITUse, setOurRecentITUse] = useState([
        { technologies_name: "JavaScript", id: 1 },
        { technologies_name: "Node.JS", id: 2 },
        { technologies_name: "React.JS", id: 3 },
        { technologies_name: "PHP", id: 4 },
        { technologies_name: "Laravel", id: 5 },
        { technologies_name: "Fluter", id: 6 },
        { technologies_name: "MySQL", id: 7 },
        { technologies_name: "MongoDB", id: 8 },
    ]);
    console.log("ourRecentITUse", ourRecentITUse)
    const [allItSupportCard, setAllItSupportCard] = useState([
        {
            id: 1,
            title: "IT AUDIT",
            short_description: "Zaimah Tech is a leading provider of IT Audit services, offering businesses a range of solutions to run their IT-related work by international standards. Our team of experienced IT auditors uses industry-standard frameworks and methodologies to conduct a thorough and comprehensive audit. We provide our clients with a detailed report outlining our findings and improvement recommendations. By partnering with Zaimah Tech for IT audit services, organisations can improve their IT systems' performance, reduce the risk of cyber-attacks and data breaches, and ensure compliance with relevant regulations and standards.",
        },
        {
            id: 2,
            title: "IT CONSULTANCY",
            short_description: "IT consultancy is a service that provides expert advice and guidance to organizations on how to effectively leverage technology to achieve their business objectives. Our team of experienced IT consultants works closely with our clients to understand their unique needs and challenges and provide tailored solutions that address them. We also provide ongoing support and maintenance services to ensure that our clients' IT systems continue to operate effectively. Through Zaimah Tech’s for IT consultancy services, organizations can enhance their technology capabilities, improve their operations, and drive growth and innovation.",
        }
    ]);
    const [recentItAuditConsultancyProjects, setRecentItAuditConsultancyProjects] = useState([
        { id: 1, image: npoly_logo, heading_name: "Npoly", web_link: "https://www.nationalpolymer.net/" },
    ]);




    return (
        <>
            <div className="container mb-5 pt-4">
                <SocialIcons />
                <div className="row g-3 mb-2">
                    <div className="col-md-6">
                        <div className="mobile__platform">
                            <h1 className="header-web-development"><span className="header-soft">IT Audit </span> & Consultancy</h1>
                        </div>
                        <div className="pt-4">
                            <GetQuoteButton />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mobile__platform__img">
                            <img src={ITAuditConsultancyBannerup} className=" img-fluid " alt="ITAuditConsultancyBannerup" border="0" />
                        </div>
                    </div>
                </div>
                {/* <div className="row g-3 pt-3">
                    <div className="col-md-6">
                        <div className="web-development-box">
                            <div className="web-development-box-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="web-development-card-title ">Back-End Development</h6>
                                        <hr className="web-development-hr" />
                                        <p className="testimonials_card_discription">
                                            Zaimah Technology back-end development team comprises 250+ full-time engineers working
                                            for companies across multiple domains. For the past 20+ years, we have successfully
                                            completed 600+ projects for more than 300+ customers worldwide.
                                        </p>
                                        <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="web-development-box">
                            <div className="web-development-box-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="web-development-card-title ">Back-End Development</h6>
                                        <hr className="web-development-hr" />
                                        <p className="testimonials_card_discription">
                                            Zaimah Technology back-end development team comprises 250+ full-time engineers working
                                            for companies across multiple domains. For the past 20+ years, we have successfully
                                            completed 600+ projects for more than 300+ customers worldwide.
                                        </p>
                                        <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="row g-3 pt-5">
                    {
                        allItSupportCard.map((item, i) => {
                            return (
                                <>
                                    <div key={i} className="col-md-6">
                                        <div className="it__audit__card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <img src={web___ux} alt="image" style={{ width: "60px", height: "60px" }} />
                                                        <h6 className="web-development-card-title ps-md-4">{item.title}</h6>
                                                    </div>
                                                    <hr className="web-development-hr" />
                                                    <p className="it__audit__card__desc">
                                                        {item.short_description}
                                                    </p>
                                                    {/* <div className="backend-development-btn">
                                                        <a src="#" type="text" className="btn-learn-more">
                                                            <span>Learn More</span>
                                                            <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        })
                    }

                </div>

                {/* <div className="row g-3 mb-2 pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">FAQ</h3>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <p className="key-web-technology-description">
                                    Starting a new project and have a few questions? Some of the most frequently asked questions
                                    and answers about custom web application development are listed below.
                                </p>
                                <div className="accordion pt-3" id="faqlist">
                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                                What is the best language for web application development ?
                                            </button>
                                        </h2>
                                        <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description"> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                                How long does it take to develop a web application ?
                                            </button>
                                        </h2>
                                        <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description">
                                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum
                                                    is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                                How much does it cost to develop a web application?
                                            </button>
                                        </h2>
                                        <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description">
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                                How to choose a technology stack for web application development?
                                            </button>
                                        </h2>
                                        <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description">
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="faq__img">
                                    <img src={Desk} className=" img-fluid" alt="Desk" border="0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>


            <section className="pt-5">
                <div className="container">
                    <div className="row g-3 mb-2">
                        <div className="col-12">
                            <h3 className="header-key-web-technology">IT Audit Services List</h3>
                            <p className="key-web-technology-description">
                                IT audit is a process of evaluating an organization's information technology systems, infrastructure,
                                and procedures to assess their effectiveness, efficiency, and security. Zaimah Tech offers IT audit
                                services to help organizations identify and mitigate potential risks and vulnerabilities in their IT systems.
                                Our IT audit services cover a wide range of areas, including:
                            </p>
                            <ol className="kwt__list">
                                <li>Information Security</li>
                                <li>IT Governance</li>
                                <li>IT Operations</li>
                                <li>Compliance</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="cloud__services__img pt-3">
                    <img src={ITAudit} alt="cloud image" loading='lazy' />
                </div>
            </section>

            <section className="pt-5">
                <div className="container">
                    <div className="row g-3 mb-2">
                        <div className="col-12">
                            <h3 className="header-key-web-technology">IT Consultancy Services List</h3>
                            <p className="key-web-technology-description">
                                Zaimah Tech offers IT consultancy services to help organizations identify, evaluate, and implement technology
                                solutions that align with their strategic goals and improve their operations.
                                Our IT consultancy services cover a wide range of areas, including:
                            </p>
                            <ol className="kwt__list">
                                <li>IT strategy</li>
                                <li>Digital transformation</li>
                                <li>Cloud computing</li>
                                <li>Cybersecurity</li>
                                <li>IT project management</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="cloud__services__img pt-3">
                    <img src={ITConsaltancy} alt="cloud image" loading='lazy' />
                </div>
            </section>



            <section className="pt-5">
                <div className="container">
                    <div className="row g-3 pt-5">
                        <div className="col-12">
                            <h3 className="header-key-web-technology">Our Recent IT Audit & Consultancy Projects</h3>
                            <div className="row g-3 gx-md-5 pt-3 d-0flex justify-content-center">
                                {
                                    recentItAuditConsultancyProjects.length > 0 && recentItAuditConsultancyProjects.map((item, id) => {
                                        return (
                                            <>
                                                <div key={id} className="col-sm-4">
                                                    <a href={`${item.web_link}`} target='_blank' className="text-decoration-none">
                                                        <div className="card__web__projects__box__card">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h5 className="card__web__projects__card__title text-center">{item.heading_name}</h5>
                                                                    <div className="card__recent__web__projects__img pt-2">
                                                                        <img src={item.image} alt="" loading='lazy' />
                                                                        {/* <img src={`${global.img_url}/images/our_recent_projects/${item.image}`} alt="" loading='lazy' /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                {/* {
                                ourRecentITProjects.length > 0 && ourRecentITProjects.map((item, id) => {
                                    return (
                                        <>
                                            <div key={id} className="col-sm-3">
                                                <div className="web-development-box">
                                                    <div className="card__web__projects__box__card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h6 className="card__web__projects__card__title">{item.projects_name}</h6>
                                                                <div className="card__web__projects__text__list">
                                                                    {
                                                                        ourRecentITUse.length > 0 && ourRecentITUse.map((item, id) => {
                                                                            return (
                                                                                <ul key={id} className="pt-1">
                                                                                    <li className="card__web__projects__text">{item.technologies_name}</li>
                                                                                </ul>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            } */}
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 pt-5 mb-4">
                        <div className="col-md-12 text-center">
                            <Link to="/products" type="text" className="btn btn-discover-all-services">Discover More Projects</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ITAuditConsultancy;