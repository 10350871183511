import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReactPhoneInput from "react-phone-input-2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import '../footer/Footer.css';
import Basis from '../../Images/Basis.svg';
import bd_img from '../../Images/bd_img.png';
import malaysia_img from '../../Images/malaysia_img.png';
import usa_flag from '../../Images/usa_flag.png';
import uk_flag from '../../Images/uk_flag.png';
import thai_flag from '../../Images/thai_flag.png';



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


const Footer = () => {
    // // Consultation popup
    // const customStyles1 = {
    //     content: {
    //         marginTop: '70px',
    //         top: '35%',
    //         left: '25%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         padding: '5px',
    //         marginRight: '-50%',
    //         transform: 'translate(-7%, -45%)',
    //         width: "55%",
    //         height: "530px",
    //         // background: "#ffffff",
    //     },
    //     overlay: { zIndex: 1000 }

    // };
    const [becomePartner, setBecomePartner] = useState({
        'first_name': '',
        'last_name': '',
        'email': '',
        'phone': '',
        'customer_type': '',
        'description': '',
        'partner_type': '',
        'country': '',
        'job_title': '',
        'notification': false,
    })

    const [modalConsultationIsOpen, setModalConsultationIsOpen] = useState(false);
    function openModalConsultation(e) {
        e.preventDefault();
        // alert("mmm")
        setModalConsultationIsOpen(true);
    }
    function closeModalConsultation(e) {
        setModalConsultationIsOpen(false);

    }

    const [phone1, setPhone1] = useState('');

    console.log('phone bal', phone1)



    const [consultation1, setConsultation1] = useState({
        'name': '',
        'email': '',
        // 'phone': '',
        'message': '',
    })
    const handleChange = (e) => {
        setConsultation1({
            ...consultation1, [e.target.name]: e.target.value
        })

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var consultation2 = {
            name: consultation1.name,
            email: consultation1.email,
            phone: phone1,
            message: consultation1.message

        }

        console.log('state data checking', consultation2)
        if (consultation1.name == '' || phone1 == '' || consultation1.message == '') {
            Swal.fire("Please fill up all inputs", '', 'error')

        }
        else {

            axios.post(`/save-consultation`, consultation2).then(res => {
                if (res.data.status == 200) {
                    Swal.fire(res.data.message, '', 'success')

                    setConsultation1({
                        'name': '',
                        'email': '',
                        'phone': '',
                        'message': '',

                    })
                    setPhone1('')
                }
                else if (res.data.status == 400) {
                    setConsultation1({ ...consultation1, error_list: res.data.errors });

                }
            })
        }
    }


    const partnerSlider = {
        // className: "center mx-4",
        // infinite: true,
        // centerPadding: "60px",
        // slidesToShow: 5,
        // swipeToSlide: true,
        // adaptiveHeight: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    // const [partner, setPartner] = useState([
    //     { id: 1, image: Google_img, title_name: "Rising Agency" },
    //     { id: 2, image: jabber_img, title_name: "Influence" },
    //     { id: 3, image: TrustPilot_img, title_name: "Marketing" },
    //     { id: 4, image: clutch_img, title_name: "Digiltally" },
    //     { id: 5, image: clutch_img, title_name: "Digiltally" },
    //     { id: 6, image: clutch_img, title_name: "Digiltally" },
    //     { id: 7, image: clutch_img, title_name: "Digiltally" },
    // ]);
    const [partner, setPartner] = useState([]);

    useEffect(() => {
        axios.get(`/partner-for-landing-page`).then(res => {
            // console.log("partner-for-landing-page DATA: ", res.data.heading);
            if (res.data.status == 200) {
                setPartner(res.data.heading);
            }
        })
    }, [])

    return (
        <section>
            <div className="pt-0">
                <div className="consultation-bg">
                    <div className="container">
                        <div className="row g-3 pt-3">
                            <div className="col-md-8">
                                <h5 className="consultation__description">If you have any question or need to schedule for a consultation</h5>
                                <h4 className="consultation__call">Please Call Us Any Time at +8809617209581</h4>
                            </div>
                            <div className="col-md-4">
                                <Modal
                                    isOpen={modalConsultationIsOpen}
                                    onRequestClose={closeModalConsultation}
                                    // style={customStyles1}
                                    className="mymodal"
                                    overlayClassName="myoverlay"
                                    contentLabel="Example Modal"
                                >
                                    <div className='card-body modal__body'>
                                        <div className="row">
                                            <div className="col-12">
                                                <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalConsultation}><i className="fa-solid fa-xmark close_btn"></i></span>
                                                <h6 className="header__consultation__text">Consultation</h6>
                                            </div>
                                        </div>
                                        <form className="pt-1" onSubmit={handleSubmit}>
                                            <div className="row mb-2">
                                                <div className="col-sm-12">
                                                    <label htmlFor="InputConsultationName" className="col-form-label col-form-label-sm d-block " >Name</label>
                                                    <input type="text" className="form-control form-control-sm " id="InputConsultationName" aria-describedby="emailHelp"
                                                        name="name" value={consultation1.name} onChange={handleChange}
                                                    />
                                                </div>

                                            </div>
                                            {/* <div className="row mb-2">
                                                <div className="col-sm-12">
                                                    <label htmlFor="InputConsultationPhoneNumber" className="col-form-label col-form-label-sm d-block" >Phone Number</label>
                                                    <input type="text" className="form-control form-control-sm " id="InputConsultationPhoneNumber" aria-describedby="emailHelp"
                                                        name="phone" value={consultation1.phone} onChange={handleChange}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="row mb-2">
                                                <div className="col-sm-12">
                                                    <label htmlFor="InputBecomePartnerMobileNo" className="col-form-label col-form-label-sm d-block" >Mobile No.</label>
                                                    <div >
                                                        <ReactPhoneInput
                                                            country='bd'
                                                            regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                                                            inputExtraProps={{
                                                                name: "phone",
                                                                required: true,
                                                                autoFocus: true
                                                            }}
                                                            inputClass="w-100"
                                                            // defaultCountry={"sg"}
                                                            value={phone1}
                                                            onChange={val => { setPhone1(val) }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-sm-12">
                                                    <label htmlFor="InputConsultationEmail" className="col-form-label col-form-label-sm d-block" >Email</label>
                                                    <input type="email" className="form-control form-control-sm " id="InputConsultationEmail" aria-describedby="emailHelp"
                                                        name="email" value={consultation1.email} onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-2 d-flex">
                                                <div className="col-sm-12">
                                                    <label htmlFor="ConsultationMessegeFormControlTextarea1" className="col-form-label col-form-label-sm " >Messege</label>
                                                    <textarea className="form-control form-control-sm" placeholder="Write Here ..." id="ConsultationMessegeFormControlTextarea1" rows="3"
                                                        name="message" value={consultation1.message} onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="text-start pt-4 mr-0">
                                                        <button type="submit" className="btn__apply"
                                                        // onClick={openModalConsultation}
                                                        >
                                                            <b>Submit</b>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Modal>
                                <div className="consultation">
                                    <button type="button" className="form-control consultation-btn mb-1"
                                        onClick={openModalConsultation}
                                    >Consultation</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className="container  pt-4 mb-4">
                        <div className="row g-3 mb-3 pt-3">
                            <h3 className="header-sub-tech-index text-center">Partner</h3>
                            <div className="col-12">
                                {/* <div className="footer__membership">
                                    <ul className="list-unstyled d-flex justify-content-center">
                                        <li><img src={google_img} className="img-fluid" alt="google_img" border="0" /></li>
                                        <li><img src={sitejobber_img} className="img-fluid" alt="sitejobber_img" border="0" /></li>
                                        <li><img src={truspilot_img} className="img-fluid" alt="truspilot_img" border="0" /></li>
                                        <li><img src={clutch_img} className="img-fluid" alt="clutch_img" border="0" /></li>
                                    </ul>
                                </div> */}
                                <div className="web__carousel">
                                    <Slider {...partnerSlider}>
                                        {
                                            partner.length > 0 && partner.map((item, id) => {
                                                return (
                                                    <div key={id} className="partner__carousel__box__card">
                                                        <div className="card">
                                                            <div className="d-flex justify-content-center partner__carousel__img pt-3">
                                                                <img src={`${global.img_url}/images/partner/${item.image}`} className="img-fluid" alt="..." loading='lazy' />
                                                            </div>
                                                            <div className="card-body">
                                                                <h5 className="partner__carousel__box__card__title">{item.heading_name}</h5>
                                                                {/* <p className="partner__carousel__box__card__disc">{item.subtitle_name}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="pt-5"></div>

                    <div className="ft__bg__color">
                        <div className="container pt-2 mb-4">
                            <div className="row g-3 pt-3 ms-2">
                                <div className="col-md-4">
                                    <h5 className="header_link_footer">Headquarter</h5>
                                    <div class="row g-1 contact__foot__bottom">
                                        <div class="col-12">
                                            <div className="bd__img">
                                                <img src={bd_img} className="img-fluid" alt="thailand_img" border="0" />
                                            </div>
                                        </div>
                                        <div class="col-12 pt-3">
                                            <span class="contact__footer d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color  fa-solid fa-phone"></i>
                                                <p class="px-3 ft__desc">+8809617209581</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-envelope"></i>
                                                <p class="px-3 ft__desc">info@zaimahtech.com</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-map-marker-alt"></i>
                                                <p class="px-3  ft__desc ">3 Dhakeswari Road, <span class="text-nowrap"> Lalbagh, Dhaka</span><br /> Bangladesh.</p></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="header_link_footer">Thailand Branch Office</h5>
                                    <div class="row g-1 contact__foot__bottom">
                                        <div class="col-12">
                                            <div className="bd__img">
                                                <img src={thai_flag} className="img-fluid" alt="thailand_img" border="0" />
                                            </div>
                                        </div>
                                        <div class="col-12 pt-3">
                                            <span class="contact__footer d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color  fa-solid fa-phone"></i>
                                                <p class="px-3 ft__desc"> +662 207 5304</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-envelope"></i>
                                                <p class="px-3 ft__desc">info@zaimahtech.com</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-map-marker-alt"></i>
                                                <p class="px-3  ft__desc ">Level 11, 540 Mercury Tower, <span class="text-nowrap"> Ploenchit Road, Lumpini,</span><br /> Pathumwan, Bangkok 10330, Thailand</p></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                   
                                    <h5 className="header_link_footer">Malaysia Partner Office</h5>
                                    <div class="row g-1 contact__foot__bottom">
                                        <div class="col-12">
                                            <div className="bd__img">
                                                <img src={malaysia_img} className="img-fluid" alt="thailand_img" border="0" />
                                            </div>
                                        </div>
                                        <div class="col-12 pt-3">
                                            <span class="contact__footer d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color  fa-solid fa-phone"></i>
                                                <p class="px-3 ft__desc">+601140693582</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-envelope"></i>
                                                <p class="px-3 ft__desc">info@zaimahtech.com</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-map-marker-alt"></i>
                                                <p class="px-3  ft__desc ">No-22, Jalan 5. Taman Selasih 1, <span class="text-nowrap"> Gombak, Batu Caves, 68100 Selangor,</span><br /> Malaysia.</p></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="header_link_footer">USA Partner Office</h5>

                                    <div class="row g-1 contact__foot__bottom">
                                        <div class="col-12">
                                            <div className="bd__img">
                                                <img src={usa_flag} className="img-fluid" alt="usa_flag" border="0" />
                                            </div>
                                        </div>
                                        <div class="col-12 pt-3">
                                            <span class="contact__footer d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color  fa-solid fa-phone"></i>
                                                <p class="px-3 ft__desc">+1(774)319-7673</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-envelope"></i>
                                                <p class="px-3 ft__desc">info@zaimahtech.com</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-map-marker-alt"></i>
                                                <p class="px-3  ft__desc ">28 Church St, <span class="text-nowrap"> Set 14 #1155 Winchester,</span><br /> Massachusetts 01890</p></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="header_link_footer">UK Partner Office</h5>

                                    <div class="row g-1 contact__foot__bottom">
                                        <div class="col-12">
                                            <div className="bd__img">
                                                <img src={uk_flag} className="img-fluid" alt="usa_flag" border="0" />
                                            </div>
                                        </div>
                                        <div class="col-12 pt-3">
                                            <span class="contact__footer d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color  fa-solid fa-phone"></i>
                                                <p class="px-3 ft__desc">+447466873974</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-envelope"></i>
                                                <p class="px-3 ft__desc">info@zaimahtech.com</p>
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span class="d-flex align-items-start ">
                                                <i class="fs-5 p-1 text__color fas fa-map-marker-alt"></i>
                                                <p class="px-3  ft__desc ">28 Broadgate, <span class="text-nowrap"> 727 Barking Road,</span><br /> London, E13 9ES</p></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 d-none d-md-block'></div>
                                
                            </div>
                            <hr className="footer-hr" />
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <h5 className="header_link_footer ps-md-5">Our Sister Concern</h5>
                                    <div className="mt-0 ps-md-5">
                                        <a href="http://dizilab.biz/" target='_blank' className="link_footer"><p>DiziLab</p></a>
                                        <a href="http://learnbyz.com/" target='_blank' to="products" className="link_footer"><p>LearnByZ</p></a>
                                        <a href="https://www.linkedin.com/company/cattle-global/" target='_blank' to="products" className="link_footer"><p>Cattle Global</p></a>
                                        <a href="http://www.elegant-international.com/" target='_blank' to="products" className="link_footer"><p>Elegant International</p></a>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <h5 className="header_link_footer ps-md-5">Our Services</h5>
                                    <div className="mt-0 ps-md-5">
                                        <Link to="web-development" className="link_footer"><p>Web Application</p></Link>
                                        <Link to="mobile-platform" className="link_footer"><p>Mobile Application</p></Link>
                                        <Link to="cloud-cyber-cecurity" className="link_footer"><p>Cloud Service & Cyber Security</p></Link>
                                        <Link to="it-support-services" className="link_footer"><p>IT Support & Hosting Services</p></Link>
                                        <Link to="it-audit-consultancy" className="link_footer"><p>IT Audit & Consultancy</p></Link>
                                        {/* <Link to="" className="link_footer"><p>Industry Revolutions (IR 4.0)</p></Link> 
                                        <Link to="" className="link_footer"><p>IT Security</p></Link>
                                        <Link to="" className="link_footer"><p>IT Support</p></Link>
                                        <Link to="ui-ux-design" className="link_footer"><p>Graphics Design</p></Link>
                                        <Link to="" className="link_footer"><p>Training & Skill Development</p></Link>*/}
                                    </div>
                                </div>
                                {/* <div className="col-md-3">
                                    <h5 className="header_link_footer ps-md-5">Tech Stack</h5>
                                    <div className="mt-0 ps-md-5">
                                        <Link to="products" className="link_footer"><p>Front-end</p></Link>
                                        <Link to="products" className="link_footer"><p>Back-end</p></Link>
                                        <Link to="products" className="link_footer"><p>UX/UI</p></Link>
                                        <Link to="products" className="link_footer"><p>Cloud Server</p></Link>
                                        <Link to="products" className="link_footer"><p>Database</p></Link>
                                        <Link to="products" className="link_footer"><p>Flutter</p></Link>
                                    </div>
                                </div> */}

                                {/* <div className="col-md-3">
                                    <h5 className="header_link_footer ps-md-5">Expertise</h5>
                                    <div className="mt-0 ps-md-5">
                                        <Link to="erp-solutions" className="link_footer"><p>ERP Solutions</p></Link>
                                        <Link to="e-commerce" className="link_footer"><p>E-commerce</p></Link>
                                        <Link to="healthcare" className="link_footer"><p>Healthcare</p></Link>
                                        <Link to="ed-tech" className="link_footer"><p>Edtech</p></Link>
                                        <Link to="" className="link_footer"><p>Hospital Management</p></Link>
                                        <Link to="" className="link_footer"><p>Tours & Travel Management</p></Link>
                                        <Link to="" className="link_footer"><p>Microfinance Software</p></Link>
                                    </div>
                                </div> */}
                                <div className="col-md-3">
                                    <h5 className="header_link_footer ps-md-5">Company</h5>
                                    <div className="mt-0 ps-md-5">
                                        <Link to="about-us" className="link_footer"><p>About Us</p></Link>
                                        <Link to="contact-us" className="link_footer"><p>Contact Us</p></Link>
                                        <Link to="career" className="link_footer"><p>Career</p></Link>
                                        {/* <Link to="" className="link_footer"><p>News</p></Link>
                                        <Link to="" className="link_footer"><p>Blogs</p></Link> */}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <h5 className="header_link_footer text-center">Membership</h5>
                                    <div className="footer__membership">
                                        <ul className="list-unstyled d-flex justify-content-center">
                                            {/* <li><img src={eCap__imgC1} className="img-fluid" alt="eCap__imgC1" border="0" loading='lazy' /></li> */}
                                            <li><img src={Basis} className="img-fluid" alt="Basis" border="0" loading='lazy' /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="footer_copyright">Copyright ©2017-2024 Zaimah Technologies Limited. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;