import React, { useState } from 'react';
import SocialIcons from './../social_Icons/SocialIcons';
import web_dev_img from '../../Images/web_dev_img.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import dawnStationary_img from '../../Images/dawnStationary_img.png';
import javascript from '../../Images/javascript.png';
import nodejs from '../../Images/nodejs.png';
import react from '../../Images/react.png';
import angular from '../../Images/angular.png';
import php from '../../Images/php.png';
import laravel from '../../Images/laravel.png';
import mysql from '../../Images/mysql.png';
import fluter from '../../Images/fluter.png';
import Desk from '../../Images/Desk.png';
import Laravel__img from '../../Images/Laravel__img.png';
import React__img from '../../Images/React__img.png';
import Node__img from '../../Images/Node__img.png';
import Mysql__img from '../../Images/Mysql__img.png';
import Flutter__img from '../../Images/Flutter__img.png';
import Angular__img from '../../Images/Angular__img.png';
import Php__img from '../../Images/Php__img.jpg';
import Js__img from '../../Images/Js__img.png';
import GetQuoteButton from '../main_home_page/GetQuoteButton';
import web___ux from '../../Images/web___ux.png';
import Logo__img1 from '../../Images/Logo__img1.png';
import AyneerGurucul from '../../Images/AyneerGurucul.png';
import BCLC_img from '../../Images/BCLC_img.png';
import ract_native_apps from '../../Images/ract_native_apps.png';
import flutter_app_deves from '../../Images/flutter_app_deves.png';
import kotlin_apps_devs from '../../Images/kotlin_apps_devs.png';
import firebase_apps_dev from '../../Images/firebase_apps_dev.png';

import dlegal_mobile_app from '../../Images/dlegal_mobile_app.png';
import mhp_marketplace_app from '../../Images/mhp_marketplace_app.png';
import pharma_pos_app from '../../Images/pharma_pos_app.png';
import great_doc_app from '../../Images/great_doc_app.png';
import app_dev_faqs from '../../Images/app_dev_faqs.png';
import MobileApp___img from '../../Images/MobileApp___img.png';





function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


const MobilePlatform = () => {
    const MobilemobilePTechFrameworksSlider = {
        // className: "center mx-4",
        // infinite: true,
        // centerPadding: "60px",
        // slidesToShow: 5,
        // swipeToSlide: true,
        // adaptiveHeight: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
        // or 
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [mobilePTechFrameworks, setMobilePTechFrameworks] = useState([
        { technologies_name: "JavaScript", technologies_img: ract_native_apps, id: 1 },
        { technologies_name: "Node.js", technologies_img: flutter_app_deves, id: 2 },
        { technologies_name: "React.js", technologies_img: kotlin_apps_devs, id: 3 },
        { technologies_name: "Angular.js", technologies_img: firebase_apps_dev, id: 4 },

    ]);
    const [ourRecentMobileProjects, setOurRecentMobileProjects] = useState([
        { projects_name: "Clinical Management System", id: 1 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 2 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 3 },
        { projects_name: "Automated Legal Contracts Generation", id: 4 },
        { projects_name: "Clinical Management System", id: 5 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 6 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 7 },
        { projects_name: "Automated Legal Contracts Generation", id: 8 },
    ]);
    const [ourRecentMobileUse, setOurRecentMobileUse] = useState([
        { technologies_name: "JavaScript", id: 1 },
        { technologies_name: "Node.JS", id: 2 },
        { technologies_name: "React.JS", id: 3 },
        { technologies_name: "PHP", id: 4 },
        { technologies_name: "Laravel", id: 5 },
        { technologies_name: "Fluter", id: 6 },
        { technologies_name: "MySQL", id: 7 },
        { technologies_name: "MongoDB", id: 8 },
    ]);
    // console.log("ourRecentMobileUse", ourRecentMobileUse)
    const [allmobilePlatformCard, setAllmobilePlatformCard] = useState([
        {
            id: 1,
            title: "Android Apps Development",
            photo: MobileApp___img,
            short_description: "Zaimah Tech's Android app development process typically starts with a discovery phase, where they work closely with clients to understand their goals, target audience, and unique needs. They then create a detailed plan for the app's development, including timelines, milestones, and deliverables. Zaimah Tech's Android app development services are designed to help clients create high- quality, user-friendly apps that meet their unique needs and goals. With a focus on quality, collaboration, and innovation, they are well-positioned to help businesses and organizations succeed in the rapidly evolving mobile landscape.",
        },
        {
            id: 2,
            title: "iOS App Development",
            photo: MobileApp___img,
            short_description: "Zaimah Tech's iOS app development service is their ability to work closely with clients to understand their needs and develop custom solutions that meet those needs. They use a variety of tools and technologies to streamline the development process and ensure that the apps they create are of the highest quality. Zaimah Tech's iOS app development services are designed to help clients create high-quality, user-friendly apps that meet their unique needs and goals. With a focus on quality, collaboration, and innovation, they are well-positioned to help businesses and organizations succeed in the rapidly evolving mobile landscape.",
        }
    ]);
    const [recentMobilePlatformProjects, setRecentMobilePlatformProjects] = useState([
        { id: 1, image: dawnStationary_img, heading_name: "Dawn Stationery", web_link: "https://play.google.com/store/search?q=dawn+stationery&c=apps" },
        { id: 2, image: great_doc_app, heading_name: "GreatDoc", web_link: "https://macrohealthplus.org/" },
        { id: 3, image: pharma_pos_app, heading_name: "GreatPharma", web_link: "https://macrohealthplus.org/" },
        { id: 4, image: mhp_marketplace_app, heading_name: "MarketPlace", web_link: "https://macrohealthplus.org/" },
    ]);
    const [askQuestion, setAskQuestiondata] = useState([
        { id: 1, faq_qtn: "What is Mobile Apps?", faq_ans: "A mobile app, or mobile application, is a software application that is designed to run on  mobile devices such as smartphones and tablets. Mobile apps are typically downloaded from an app store such as the Apple App Store or Google Play Store, and can be used for a variety of purposes such as entertainment, social networking, productivity, education, and more." },
        {
            id: 2, faq_qtn: "What Technologies are used to develop Mobile Apps?", faq_ans: "There are several technologies used to develop mobile apps, and the choice of technology depends on various factors such as the app's requirements, platform compatibility, and development team's expertise. Here are some of the technologies commonly used to develop mobile apps:",
            faq_ans_list: [
                { id: 1, qus: "React Native" },
                { id: 2, qus: "Flutter" },
                { id: 3, qus: "Kotlin" },
                { id: 4, qus: "Firebase" },
                { id: 5, qus: "Swift" },
            ]
        },
        { id: 3, faq_qtn: "How many Devices are used mobile Apps?", faq_ans: "There several types of devices now a days using mobile apps. Android, iOS, Windows, and Blackberry." },
        { id: 4, faq_qtn: "What are the main steps in a mobile app development project?", faq_ans: "Feasibility Studies – Our team of experts conduct detailed research to establish that your app idea has merit. Design and Development – This is the stage where we bring out our big guns – our tech team – to design and develop your app idea. Testing and Verification – We believe development and testing go hand in hand. This has helped us avoid numerous costly mistakes on behalf of our clients. Documentation – We document every step of the tech journey you undertake with us. This gives us detailed notes to fall back on in the future and also come up with innovative ideas. Product Launch and Marketing – Once your final product is all ready to be launched, we also help you come up with creative marketing solutions. Product Evolution – Now, this is a crucial step. We believe in continuous learning when it comes to keeping up with the latest technology. This dedication to state-of-the-art services helps us make sure that your app evolves with the times." },
        {
            id: 5, faq_qtn: "How much does it cost to develop a mobile app?", faq_ans: "There is no cut-and-dried answer to this question. The cost of building a mobile app will be solely dependent on the features and complexity desired by the app owner. You need to consider a number of factors before you can decide on a budget for your mobile app development project, some of which are:",
            faq_ans_list: [
                { id: 1, qus: "How complex do you want your front-end to be?" },
                { id: 2, qus: "Is there a steep deadline that you must meet?" },
                { id: 3, qus: "What are the stakeholder expectations for your mobile app?" },
                { id: 4, qus: "How detailed do you want your UI to be?" },
                { id: 5, qus: "How large a load must your app’s back-end be able to handle?" },
                { id: 6, qus: "What are your financial projections like?" },
                { id: 7, qus: "How soon do you wish to break even on your app’s development costs?" },
            ]
        },
        { id: 6, faq_qtn: "Which is one of the most important aspect of mobile app development?", faq_ans: "Mobile users demand intuitiveness, ease of use, and simplicity. A crucial strategy for success in developing an app is to ensure that the interface design isn't cluttered with unnecessary features." },
    ]);
    console.log("askQuestion", askQuestion);


    const [activePanel, setActivePanel] = useState(1);
    const togglePanel = (panel) => {
        setActivePanel(activePanel === panel ? 0 : panel);
    };
    const [allFaqImage, setAllFaqImage] = useState([
        { id: 1, faq_img: app_dev_faqs },
    ]);

    return (
        <>
            <div className="container mb-5 pt-4">
                <SocialIcons />
                <div className="row g-3 mb-2">
                    <div className="col-md-6">
                        <div className="mobile__platform">
                            <h1 className="header-web-development">Mobile <span className="header-soft">Platform</span></h1>
                        </div>
                        <div className="pt-4">
                            <GetQuoteButton />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mobile__platform__img">
                            <img src={web_dev_img} className=" img-fluid " alt="web_dev_img" border="0" />
                        </div>
                    </div>
                </div>
                {/* <div className="row g-3 pt-3">
                    <div className="col-md-6">
                        <div className="web-development-box">
                            <div className="web-development-box-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="web-development-card-title ">Android Apps Development</h6>
                                        <hr className="web-development-hr" />
                                        <p className="testimonials_card_discription">
                                            ZTL is a leading Android mobile app development company. Our team of experienced and skilled Android
                                            developers, designers and testers are always ready to answer your questions and help you transform
                                            your idea into an app. We have experience in app development, working with clients all over Bangladesh.
                                            We know how to use various technologies and offer customized solutions to a wide variety of dimensions.
                                            As a client, you want to know your project is always moving forward. A flexible and constant approach
                                            guarantees  best results at all times. At ZTL, we use an agile process to stay connected to our clients
                                            throughout the development process.
                                        </p>
                                       <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="web-development-box">
                            <div className="web-development-box-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="web-development-card-title ">IOS development</h6>
                                        <hr className="web-development-hr" />
                                        <p className="testimonials_card_discription">
                                            It's no secret that Apple comes with a lot of restrictions and limitations that other competitors
                                            don't have, but we have found some ways to add a little extra joy to the Apple App Store. We care
                                            about the needs of our customers, which is why we're so excited to bring you high-quality apps that
                                            provide enough features to meet all your requirements, with a seamless and intuitive user experience.
                                            We help clients &amp; customers bring their ideas to life by developing high-quality iOS apps.
                                            Whatever your vision for the next big Apple product, we will help you achieve it in the most
                                            efficient ways.
                                        </p>
                                   <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row g-3 pt-5">
                    {
                        allmobilePlatformCard.map((item, i) => {
                            return (
                                <>
                                    <div className="col-md-6">
                                        <div className="mobile__platform__card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <img src={item.photo} alt="image" style={{ width: "60px", height: "60px" }} />
                                                        <h6 className="web-development-card-title ps-md-4">{item.title}</h6>
                                                    </div>
                                                    <hr className="web-development-hr" />
                                                    <p className="mobile__platform__card__desc">
                                                        {item.short_description}
                                                    </p>
                                                    {/* <div className="backend-development-btn">
                                                        <a src="#" type="text" className="btn-learn-more">
                                                            <span>Learn More</span>
                                                            <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        })
                    }

                </div>



                <div className="row g-3 mb-2  pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">Key Web Technologies and Frameworks</h3>
                        {/* <h3 className="header-key-web-technology">Frameworks</h3> */}
                        <p className="key-web-technology-description">
                            Zaimah Tech is a software firm that specializes in mobile app development, and they use a
                            variety of technologies and frameworks to create high-quality, feature-rich apps for their
                            clients. Here are some of the technologies and frameworks that Zaimah Tech uses in their
                            mobile app development projects:
                        </p>
                        <ol className="kwt__list">
                            <li>React Native</li>
                            <li>Fullter</li>
                            <li>Kotlin</li>
                            <li>Firebase</li>
                        </ol>
                    </div>
                </div>
                <div className="row pt-3 mb-4">
                    <div className="col-12">
                        <div className="web__carousel">
                            <Slider {...MobilemobilePTechFrameworksSlider}>
                                {
                                    mobilePTechFrameworks.length > 0 && mobilePTechFrameworks.map((item, id) => {
                                        return (
                                            <div key={id} className="row g-2">
                                                <div className="col-12">
                                                    <div className="key__web__carousel__box__card">
                                                        <div className="card text-center">
                                                            {/* <div className="d-flex justify-content-center pt-4">
                                                                <img src={item.technologies_img} className="card-img-top" alt="..." style={{ width: "50px", height: "40px" }} />
                                                            </div>
                                                            <div className="card-body">
                                                                <p className="card__web__projects__text__list">{item.technologies_name}</p>
                                                            </div> */}
                                                            <div className="key__web__carousel">
                                                                <img src={item.technologies_img} className="img-fluid" alt="..." loading='lazy' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="row g-3 pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">Our Recent Mobile Platform Projects</h3>
                        <div className="row g-3 gx-md-5 pt-3">
                            {
                                recentMobilePlatformProjects.length > 0 && recentMobilePlatformProjects.map((item, id) => {
                                    return (
                                        <>
                                            <div key={id} className="col-sm-6">
                                                <a href={`${item.web_link}`} target='_blank' className="text-decoration-none">
                                                    <div className="mobile__plateform__projects__box__card">
                                                        <div className="card">
                                                            <h5 className="mobile__platform__projects__card__title text-center pt-3">{item.heading_name}</h5>
                                                            <div className="mobile__platform__projects__img pt-3">
                                                                <img src={item.image} alt="" loading='lazy' />
                                                                {/* <img src={`${global.img_url}/images/our_recent_projects/${item.image}`} alt="" loading='lazy' /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </>
                                    )
                                })
                            }
                            {/* {
                                ourRecentMobileProjects.length > 0 && ourRecentMobileProjects.map((item, id) => {
                                    return (
                                        <>
                                            <div key={id} className="col-sm-3">
                                                <div className="web-development-box">
                                                    <div className="card__web__projects__box__card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h6 className="card__web__projects__card__title">{item.projects_name}</h6>
                                                                <div className="card__web__projects__text__list">
                                                                    <ul className="pt-1">
                                                                        {
                                                                            ourRecentMobileUse.length > 0 && ourRecentMobileUse.map((item, id) => {
                                                                                return (
                                                                                    <li key={id} className="card__web__projects__text">{item.technologies_name}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            } */}
                        </div>
                    </div>
                </div>
                <div className="row g-3 pt-5 mb-3">
                    <div className="col-md-12 text-center">
                        <Link to="/products" type="text" className="btn btn-discover-all-services">Discover More Projects</Link>
                    </div>
                </div>

                <div className="row g-3 pt-5">
                    <h3 className="header-key-web-technology">FAQs - FREQUENTLY ASK AND QUESTIONS</h3>
                    <div className="col-md-6">
                        <p className="key-web-technology-description">
                            Starting a new project and have a few questions? Some of the most frequently asked questions
                            and answers about custom web application development are listed below.
                        </p>

                        <div className="pt-3">
                            {
                               askQuestion.length >0 && askQuestion.map((panel, i) => (
                                    <div key={i} className="card mb-3">
                                        <div
                                            className="card-header"
                                            id={`heading${panel.id}`}
                                            onClick={() => togglePanel(panel.id)}
                                        >
                                            <div className="question__ask d-flex justify-content-between">
                                                <p className="ask_qustion__txt">{panel.faq_qtn}</p>
                                                <p className="ask_qustion__icon">
                                                    <FontAwesomeIcon
                                                        // icon={activePanel === panel.id ? faCaretUp : faCaretDown}
                                                        icon={activePanel === panel.id ? faMinus : faPlus}
                                                        className="pull-right me-2"
                                                    />
                                                </p>
                                            </div>
                                        </div>

                                        <div className={`collapse ${activePanel === panel.id ? "show" : ""}`}>
                                            <div className="card-body">
                                                <p className="ask_qustion__content__txt">{panel.faq_ans}</p>
                                                <ol className="kwt__list">
                                                    {
                                                       panel.faq_ans_list !==undefined && panel.faq_ans_list.map((item, id) => {
                                                            return (
                                                                <li key={id}>{item.qus}</li>
                                                            )
                                                        })
                                                    }
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        {
                            allFaqImage.map((item, i) => {
                                return (
                                    <>
                                        <div key={i} className="mobile__platform__faq__img">
                                            <img src={item.faq_img} className="img-fluid" alt="Desk" border="0" loading='lazy' />
                                        </div>
                                    </>

                                )
                            })
                        }

                    </div>
                </div>


                {/* <div className="row g-3 mb-2 pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">FAQ</h3>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <p className="key-web-technology-description">
                                    Starting a new project and have a few questions? Some of the most frequently asked questions
                                    and answers about custom web application development are listed below.
                                </p>
                                <div className="accordion pt-3" id="faqlist">
                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                                What is the best language for web application development ?
                                            </button>
                                        </h2>
                                        <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description"> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                                How long does it take to develop a web application ?
                                            </button>
                                        </h2>
                                        <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description">
                                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum
                                                    is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                                How much does it cost to develop a web application?
                                            </button>
                                        </h2>
                                        <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description">
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-3 border">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed key-web-technology-description" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                                How to choose a technology stack for web application development?
                                            </button>
                                        </h2>
                                        <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                            <div className="accordion-body">
                                                <p className="key-web-technology-description">
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="faq__img">
                                    <img src={Desk} className=" img-fluid" alt="Desk" border="0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default MobilePlatform;