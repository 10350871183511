import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
// import EntSetupSidebar from '../ent_setup_mainsidebar/EntSetupSidebar';
import MasterDashboardLayout from '../../../dashboard/MasterDashboardLayout';
import '../../../img_url';

function EditPartnerForHomePage() {
    const navigate = useNavigate();
    const [partner, setPartner] = useState({
        heading_name: "",
    });

    const [image, setImage] = useState('');
    const [picture, setPicture] = useState('');


    const { id } = useParams();
    // console.log('id checkibg',id)
    useEffect(() => {
        axios.get(`/edit-partner-for-landing-page/${id}`).then(res => {
            if (res.data.status == 200) {
                // setPartner(res.data.heading);
                setPartner({
                    heading_name: res.data.partner.heading_name,

                })
                setImage(res.data.partner.image)
                // console.log('image chek',res.data.partner.image)
            }
        })

    }, [])

    const onChangePicture = e => {
        console.log('picture: ', picture);
        setPicture(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0]);
    };

    const handleInput = (e) => {
        setPartner({
            ...partner, [e.target.name]: e.target.value
        });
    }


    const updateHeading = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('heading_name', partner.heading_name);
        formData.append('image', image);

        if (image == '') {
            Swal.fire("Please fill up all inputs", '', 'error')

        }
        else if (image.size > 524288) {
            Swal.fire("Image size must be less than 0.5 mb", '', 'error')

        }

        else {
            axios.post(`/update-partner-for-landing-page/${id}`, formData).then(res => {
                if (res.data.status == 200) {
                    Swal.fire(res.data.message, '', 'success')


                    setImage('');
                    setPicture('');
                    document.getElementById('heading_image').value = "";
                }
                navigate('/partner-for-landing-page');
            })
        }
    }

    return (
        <>
            <MasterDashboardLayout>
                <div className="container-fluid">
                    <div className="row">
                        {/* <div className="col-md-3">
                            <EntSetupSidebar/>
                        </div> */}
                        <div className="col-md-12 mt-3">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="card-title"> Edit partner
                                        <Link to={'/partner-for-landing-page'} className="btn btn-primary btn-sm float-end"> Back </Link>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <form id="EarCanalForm" onSubmit={updateHeading} >
                                        <div className="card-body">

                                            <div className="col-md-12">


                                                <div className="form-group">
                                                    <label htmlFor="history_name" className="col-form-label-sm"> Heading Name </label>
                                                    <input type="text" value={partner.heading_name} className="form-control" name="heading_name" onChange={handleInput} />
                                                    {/* <span className="text-danger">{partner.error_list.heading_name}</span> */}

                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="history_name" className="col-form-label-sm"> Image </label>
                                                    <input type="file" className="form-control" name="image" onChange={onChangePicture} />
                                                    {/* <span className="text-danger">{partner.error_list.heading_name}</span> */}

                                                </div>

                                                {
                                                    picture == '' ? <div className="form-group" id="heading_image" style={{ width: '100px', height: '90px' }}>
                                                        <img className="playerProfilePic_home_tile" src={`${global.img_url}/images/partner/${image}`} style={{ width: '100px', height: '90px' }}></img>
                                                    </div>
                                                        :
                                                        <div className="form-group" style={{ width: '100px', height: '90px' }}>
                                                            <img className="playerProfilePic_home_tile" src={picture} style={{ width: '100px', height: '90px' }}></img>
                                                        </div>
                                                }



                                                {
                                                    image.size > 524288 && <div className='text-danger mt-4'>Image Size Must be less than 0.5 Mb </div>
                                                }


                                                <div className="float-right">
                                                    <button type="submit" className="btn btn-sm btn-primary text-uppercase float-end mt-2"><i
                                                        className="fas fa-save"></i> Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </MasterDashboardLayout>
        </>
    )
}

export default EditPartnerForHomePage
