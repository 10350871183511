import React, { useState } from 'react';
import SocialIcons from './../social_Icons/SocialIcons';
import healthcare_dev from '../../Images/healthcare_dev.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetQuoteButton from '../main_home_page/GetQuoteButton';


import javascript from '../../Images/javascript.png';
import nodejs from '../../Images/nodejs.png';
import react from '../../Images/react.png';
import angular from '../../Images/angular.png';
import php from '../../Images/php.png';
import laravel from '../../Images/laravel.png';
import mysql from '../../Images/mysql.png';
import fluter from '../../Images/fluter.png';
import healthcare_doctors from '../../Images/healthcare_doctors.png';
import healthcare_patients from '../../Images/healthcare_patients.png';
import healthcare_mangements from '../../Images/healthcare_mangements.png';
import web___ux from '../../Images/web___ux.png';
import Logo__img1 from '../../Images/Logo__img1.png';
import AyneerGurucul from '../../Images/AyneerGurucul.png';
import BCLC_img from '../../Images/BCLC_img.png';
import ecommerce____img from '../../Images/ecommerce____img.png';
import payment_img from '../../Images/payment_img.png';
import Ecommerce_Img from '../../Images/Ecommerce_Img.jpg';
import ecommercmapp from '../../Images/ecommercmapp.png';
import ecommercwebapp from '../../Images/ecommercwebapp.png';
import e_techweb from '../../Images/e_techweb.png';
import e_shopify from '../../Images/e_shopify.png';
import e_magento from '../../Images/e_magento.png';
import e_wooc from '../../Images/e_wooc.png';
import eocommerc from '../../Images/eocommerc.png';
import crm from '../../Images/crm.png';
import payment from '../../Images/payment.png';
import mobileapp from '../../Images/mobileapp.png';
import customweb from '../../Images/customweb.png';
import e_magentoBG from '../../Images/e_magentoBG.png';
import inventory from '../../Images/inventory.png';
import bbb from '../../Images/bbb.avif';



const ECommerce = () => {
    const EcommerceTechFrameworksSlider = {
        // className: "center mx-4",
        // infinite: true,
        // centerPadding: "60px",
        // slidesToShow: 5,
        // swipeToSlide: true,
        // adaptiveHeight: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const OurEcommerceTSolutionsSlider = {
        // className: "center mx-4",
        // infinite: true,
        // centerPadding: "60px",
        // slidesToShow: 3,
        // swipeToSlide: true,
        // adaptiveHeight: false,
        // // adaptiveHeight: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [ecommerceTechFrameworks, setEcommerceTechFrameworks] = useState([
        { technologies_name: "JavaScript", technologies_img: eocommerc, id: 1 },
        { technologies_name: "Node.js", technologies_img: e_shopify, id: 2 },
        { technologies_name: "React.js", technologies_img: e_magento, id: 3 },
        { technologies_name: "Angular.js", technologies_img: e_wooc, id: 4 },
        { technologies_name: "PHP", technologies_img: e_techweb, id: 5 },

    ]);
    const [ourEcommerceITSolutions, setOurHealthcareITSolutions] = useState([
        {
            description: "We design and develop customized e-commerce websites that provide a seamless and intuitive user experience for customers.",
            title_name: "E-commerce website development", technologies_img: e_magentoBG, id: 1
        },
        {
            description: "We develop customized e-commerce mobile applications that enable businesses to reach customers via mobile devices.",
            title_name: "Mobile application development", technologies_img: mobileapp, id: 2
        },
        {
            description: "We integrate payment gateways into e-commerce websites and mobile applications to enable secure and seamless payment transactions.",
            title_name: "Payment gateway integration", technologies_img: payment_img, id: 3
        },
        {
            description: "We develop customized inventory management solutions that help businesses to manage their inventory and streamline their operations..",
            title_name: "Inventory management", technologies_img: inventory, id: 4
        },
        {
            description: "We develop customized CRM solutions that help businesses to manage their customer relationships and improve customer retention.",
            title_name: "Customer relationship management (CRM)", technologies_img: crm, id: 5
        },

    ]);
    const [allECommerceCard, setAllECommerceCard] = useState([
        {
            id: 1,
            image: ecommercwebapp,
            title: "E-commerce Web Development",
            short_description: "Successful e-commerce web development requires expertise in several key areas, including front-end and back-end development, web design, user experience (UX) design, e-commerce technologies, and search engine optimization (SEO). Some popular e-commerce platforms used for web development include Shopify, Magento, WooCommerce, and BigCommerce. E-commerce web development is essential for businesses that want to establish a strong online presence and compete in the fast-growing e-commerce market. With the right e-commerce website, businesses can increase their reach, boost sales, and deliver personalized and convenient experiences to their customers, driving growth and success for their business",
        },
        {
            id: 2,
            image: ecommercmapp,
            title: "E-commerce Mobile Apps",
            short_description: "Our goal is to help businesses create high-quality mobile applications that enable them to sell their products and services to customers via mobile devices. We work closely with our clients to understand their business goals, target audience, and technical requirements, and develop custom mobile applications that meet their unique needs and exceed their expectations. Our team of experienced mobile application developers has expertise in several key areas, including front-end and back-end development, user experience (UX) design, e-commerce technologies, and mobile app development frameworks such as React Native and Flutter. We leverage the latest mobile app development tools and technologies to create robust, secure, and scalable mobile applications that provide a seamless shopping experience for customers.",
        }
    ]);



    return (
        <>
            <div className="container mb-5 pt-4">
                <SocialIcons />
                <div className="row g-3 mb-3">
                    <div className="col-md-7 pe-md-5">
                        <div className="healthcare">
                            <h1 className="header-web-development"><span className="header-soft">Transforming</span> e-commerce with Fintech innovation</h1>
                        </div>
                        <div className="pt-4">
                            <GetQuoteButton />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="healthcare__dev">
                            <img src={Ecommerce_Img} className="img-fluid" alt="ecommerce____img" border="0" loading='lazy' />
                        </div>
                    </div>
                </div>
                {/* <div className="row g-3 pt-5">
                    <div className="col-md-6">
                        <div className="healthcare__box">
                            <div className="healthcare__box__card">
                                <div className="card pt-2">
                                    <div className="card-body">
                                        <h6 className="healthcare__box__card__title">Ecommerce Web App Development</h6>
                                        <hr className="healthcare__hr" />
                                        <p className="healthcare__box__card__discription">
                                            Zaimah Technology back-end development team comprises 250+ full-time engineers
                                            working for companies across multiple domains. For the past 20+ years, we have
                                            successfully completed 600+ projects for more than 300+ customers worldwide.
                                        </p>
                                        <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="healthcare__box">
                            <div className="healthcare__box__card">
                                <div className="card pt-2">
                                    <div className="card-body">
                                        <h6 className="healthcare__box__card__title ">E-commerce Mobile App Development</h6>
                                        <hr className="healthcare__hr" />
                                        <p className="healthcare__box__card__discription">
                                            With 15+ years of experience and 170+ Javascript developers on board, Zaimah Technology provides
                                            its clients with top-notch front end web development solutions. We have successfully implemented
                                            more than 50 projects for over 40 clients across the globe.
                                        </p>
                                        <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row g-3 pt-5">
                    {
                        allECommerceCard.map((item, i) => {
                            return (
                                <>
                                    <div key={i} className="col-md-6">
                                        <div className="web-development-box-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <img src={item.image} alt="image" style={{ width: "60px", height: "60px" }} />
                                                        <h6 className="web-development-card-title ps-md-4">{item.title}</h6>
                                                    </div>
                                                    <hr className="web-development-hr" />
                                                    <p className="ecommerce__card__desc">
                                                        {item.short_description}
                                                    </p>
                                                    {/* <div className="backend-development-btn">
                                                        <a src="#" type="text" className="btn-learn-more">
                                                            <span>Learn More</span>
                                                            <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        })
                    }

                </div>

                <div className="row g-3 mb-2 pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">Key E-commerce Technologies and Frameworks</h3>
                        {/* <h3 className="header-key-web-technology">Frameworks</h3> */}
                        <p className="key-web-technology-description">
                            E-commerce technologies and frameworks play a crucial role in enabling online businesses to offer seamless and personalized experiences to their customers. Here are some of the key e-commerce technologies and frameworks:
                        </p>
                        <ol className='ect__txt'>
                            <li>Content Management Systems (CMS).</li>
                            <li>Customer Relationship Management (CRM).</li>
                            <li>Payment Gateways.</li>
                            <li>Cloud Computing.</li>
                            <li>Search Engine Optimization (SEO)</li>
                            <li>Mobile Commerce</li>
                        </ol>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-12">
                        <div className="web__carousel">
                            <Slider {...EcommerceTechFrameworksSlider}>
                                {
                                    ecommerceTechFrameworks.length > 0 && ecommerceTechFrameworks.map((item, id) => {
                                        return (
                                            <div key={id} className="row g-2">
                                                <div className="col-12">
                                                    <div className="ecommerce__carousel__box__card">
                                                        <div className="card text-center">
                                                            {/* <div className="d-flex justify-content-center pt-4">
                                                                <img src={item.technologies_img} className="card-img-top" alt="..." style={{ width: "50px", height: "40px" }} />
                                                            </div>
                                                            <div className="card-body">
                                                                <p className="web__carousel__box__card__discription ">{item.technologies_name}</p>
                                                            </div> */}
                                                            <div className="ecommerce__carousel">
                                                                <img src={item.technologies_img} className="img-fluid" alt="image" loading='lazy' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="row g-3 mb-2  pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">Our E-commerce IT Solutions</h3>
                        <p className="key-web-technology-description">
                            We offer a range of e-commerce IT solutions that help businesses to streamline their operations, improve their customer experience, and increase their sales revenue. Our e-commerce IT solutions are designed to meet the unique needs of each client, and we work closely with businesses to understand their requirements and develop customized solutions that meet their specific needs.
                            We are committed to delivering high-quality e-commerce IT solutions that help businesses to achieve their goals and exceed their customers' expectations. With our expertise in e-commerce technologies, we help businesses to stay ahead of the competition and capitalize on the growing trend of e-commerce.
                        </p>
                    </div>
                </div>
                <div className="row mb-5 pt-3">
                    <div className="col-12">
                        <div className="web__carousel">
                            <Slider {...OurEcommerceTSolutionsSlider}>
                                {
                                    ourEcommerceITSolutions.length > 0 && ourEcommerceITSolutions.map((item, id) => {
                                        return (
                                            <div key={id} className="row g-2">
                                                <div className="col-12">
                                                    <div className="ecommerce__it__carousel__box__card">
                                                        <div className="card text-center">
                                                            <div className="d-flex justify-content-center pt-4">
                                                                <img src={item.technologies_img} className="card-img-top" alt="image" style={{ width: "80px", height: "85px", borderRadius: "5px" }} />
                                                            </div>
                                                            <div className="card-body">
                                                                <h6 className="header-soft mb-2">{item.title_name}</h6>
                                                                <p className="ecommerce__it__carousel__discription">{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ECommerce;