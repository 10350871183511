import React, { useState } from 'react';
import SocialIcons from '../social_Icons/SocialIcons';
import web_dev_img from '../../Images/web_dev_img.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

import javascript from '../../Images/javascript.png';
import nodejs from '../../Images/nodejs.png';
import react from '../../Images/react.png';
import angular from '../../Images/angular.png';
import php from '../../Images/php.png';
import laravel from '../../Images/laravel.png';
import mysql from '../../Images/mysql.png';
import fluter from '../../Images/fluter.png';
import Desk from '../../Images/Desk.png';
import GetQuoteButton from '../main_home_page/GetQuoteButton';
import web___ux from '../../Images/web___ux.png';
import Logo__img1 from '../../Images/Logo__img1.png';
import AyneerGurucul from '../../Images/AyneerGurucul.png';
import BCLC_img from '../../Images/BCLC_img.png';
import cyber_sec_img from '../../Images/cyber_sec_img.png';
import cloud_cyber_security from '../../Images/cloud_cyber_security.png';
import cloud_service_img from '../../Images/cloud_service_img.png';
import cyber_sec_services from '../../Images/cyber_sec_services.png';



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


const CloudCyberSecurity = () => {
    const CloudCyberSecurityFrameworksSlider = {
        // className: "center mx-4",
        // infinite: true,
        // centerPadding: "60px",
        // slidesToShow: 5,
        // swipeToSlide: true,
        // adaptiveHeight: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
        // or 
        className: "center",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [cloudsecurityTechFrameworks, setCloudCyberSecurityTechFrameworks] = useState([
        { technologies_name: "JavaScript", technologies_img: javascript, id: 1 },
        { technologies_name: "Node.js", technologies_img: nodejs, id: 2 },
        { technologies_name: "React.js", technologies_img: react, id: 3 },
        { technologies_name: "Angular.js", technologies_img: angular, id: 4 },
        { technologies_name: "PHP", technologies_img: php, id: 5 },
        { technologies_name: "Laravel", technologies_img: laravel, id: 6 },
        { technologies_name: "MySql", technologies_img: mysql, id: 7 },
        { technologies_name: "Fluter", technologies_img: fluter, id: 8 },
        { technologies_name: "PHP", technologies_img: php, id: 9 },

    ]);
    const [ourRecentCloudCyberSecurityProjects, setOurRecentCloudCyberSecurityProjects] = useState([
        { projects_name: "Clinical Management System", id: 1 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 2 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 3 },
        { projects_name: "Automated Legal Contracts Generation", id: 4 },
        { projects_name: "Clinical Management System", id: 5 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 6 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 7 },
        { projects_name: "Automated Legal Contracts Generation", id: 8 },
    ]);
    const [ourRecentCloudCyberSecurityUse, setOurRecentCloudCyberSecurityUse] = useState([
        { technologies_name: "JavaScript", id: 1 },
        { technologies_name: "Node.JS", id: 2 },
        { technologies_name: "React.JS", id: 3 },
        { technologies_name: "PHP", id: 4 },
        { technologies_name: "Laravel", id: 5 },
        { technologies_name: "Fluter", id: 6 },
        { technologies_name: "MySQL", id: 7 },
        { technologies_name: "MongoDB", id: 8 },
    ]);
    console.log("ourRecentCloudCyberSecurityUse", ourRecentCloudCyberSecurityUse)

    const [allCloudSecurityCard, setAllCloudSecurityCard] = useState([
        {
            id: 1,
            title: "CLOUD SERVICE",
            short_description: "Zaimah Tech is a leading provider of cloud services that help businesses operate more efficiently and securely in the digital age. With their cloud solutions, Zaimah Tech provides customers with a range of benefits, including improved collaboration, enhanced security, and increased flexibility. Zaimah Tech's cloud services enable businesses to operate more efficiently, securely, and flexibly in the digital age. With their range of cloud solutions, businesses can easily scale up or down their infrastructure, develop and deploy applications more quickly, and store and access their data from anywhere, at any time.",
        },
        {
            id: 2,
            title: "CYBER SECUIRITY SERVICE",
            short_description: "Zaimah Tech is a leading provider of cyber security services, offering businesses a range of solutions to protect their digital assets from cyber threats. With the rise of cyber attacks and data breaches, cyber security has become a critical concern for businesses of all sizes. Zaimah Tech provides businesses with the tools and expertise they need to stay secure and protect their sensitive data. Zaimah Tech's cyber security services provide businesses with the tools and expertise they need to stay secure in today's digital age. With their range of solutions, businesses can identify vulnerabilities, monitor their IT infrastructure, manage user access, educate their employees, and meet compliance requirements. Zaimah Tech's commitment to cyber security ensures that their customers can focus on growing their business, knowing that their digital assets are secure.",
        }
    ]);

    const [recentCloudCyberSecurityProjects, setRecentCloudCyberSecurityProjects] = useState([
        { id: 1, image: Logo__img1, heading_name: "MacroHealthPlus", web_link: "https://macrohealthplus.org/" },
        { id: 2, image: AyneerGurucul, heading_name: "Ayeener Gurukul", web_link: "http://www.ayneergurukul.com/" },
        { id: 3, image: BCLC_img, heading_name: "BCLC Project", web_link: "https://dev.bclcbd.com/" },
    ]);


    return (
        <>
            <div className="container mb-5 pt-4">
                <SocialIcons />
                <div className="row g-3 mb-2">
                    <div className="col-md-6">
                        <div className="mobile__platform">
                            <h1 className="header-web-development">Cloud Service <span className="header-soft">& Cyber Security</span></h1>
                        </div>
                        <div className="pt-4">
                            <GetQuoteButton />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mobile__platform__img">
                            <img src={cyber_sec_img} className=" img-fluid " alt="cyber_sec_img" border="0" />
                        </div>
                    </div>
                </div>
                {/* <div className="row g-3 pt-3">
                    <div className="col-md-6">
                        <div className="web-development-box">
                            <div className="web-development-box-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="web-development-card-title ">Back-End Development</h6>
                                        <hr className="web-development-hr" />
                                        <p className="testimonials_card_discription">
                                            Zaimah Technology back-end development team comprises 250+ full-time engineers working
                                            for companies across multiple domains. For the past 20+ years, we have successfully
                                            completed 600+ projects for more than 300+ customers worldwide.
                                        </p>
                                        <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="web-development-box">
                            <div className="web-development-box-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="web-development-card-title ">Back-End Development</h6>
                                        <hr className="web-development-hr" />
                                        <p className="testimonials_card_discription">
                                            Zaimah Technology back-end development team comprises 250+ full-time engineers working
                                            for companies across multiple domains. For the past 20+ years, we have successfully
                                            completed 600+ projects for more than 300+ customers worldwide.
                                        </p>
                                        <div className="backend-development-btn">
                                            <a src="#" type="text" className="btn-learn-more">
                                                <span>Learn More</span>
                                                <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="row g-3 pt-5">
                    {
                        allCloudSecurityCard.map((item, i) => {
                            return (
                                <>
                                    <div className="col-md-6">
                                        <div className="cloud__security__card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <img src={web___ux} alt="image" style={{ width: "60px", height: "60px" }} />
                                                        <h6 className="web-development-card-title ps-md-4">{item.title}</h6>
                                                    </div>
                                                    <hr className="web-development-hr" />
                                                    <p className="cloud__security__card__desc">
                                                        {item.short_description}
                                                    </p>
                                                    {/* <div className="backend-development-btn">
                                                        <a src="#" type="text" className="btn-learn-more">
                                                            <span>Learn More</span>
                                                            <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        })
                    }

                </div>

            </div>

            <section className="pt-5">
                <div className="container border">
                    <div className="row g-3 my-2 d-flex align-items-center">
                        <div className="col-md-3">
                            <h6 className="cloud__services__title__txt">CLOUD COMPUTING SERVICES</h6>
                        </div>
                        <div className="col-md-9">
                            <div>
                                <p className="cloud__services__desc__txt">
                                    Zaimah Tech's cloud service offerings are designed to meet the needs of businesses of all sizes, from small startups to large enterprises. They offer a range of cloud services, including:

                                    Infrastructure as a Service (IaaS): Zaimah Tech's IaaS solutions provide businesses with the computing power, storage, and networking resources they need to run their applications and services. Customers can easily scale up or down their infrastructure based on their changing needs, without the need to invest in expensive hardware.

                                    Platform as a Service (PaaS): Zaimah Tech's PaaS solutions enable businesses to develop, deploy, and manage their applications without the need to manage the underlying infrastructure. This enables businesses to focus on developing their applications, while Zaimah Tech takes care of the rest.

                                    Software as a Service (SaaS): Zaimah Tech's SaaS solutions provide businesses with access to a range of software applications, such as productivity tools, customer relationship management (CRM) software, and more. These solutions are delivered over the internet and can be accessed from anywhere, at any time.

                                    Cloud Storage: Zaimah Tech's cloud storage solutions provide businesses with secure, reliable, and scalable storage for their data. This enables businesses to store and access their data from anywhere, while also ensuring that their data is protected from data loss or theft.

                                    Cloud Backup: Zaimah Tech's cloud backup solutions provide businesses with automated backup and recovery of their critical data. This ensures that businesses can recover their data quickly and easily in the event of a data loss or disaster.

                                    In addition to these cloud services, Zaimah Tech also provides customers with a range of tools and services to help them manage their cloud environments. These include monitoring and reporting tools, security and compliance services, and support services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cloud__services__img pt-5">
                    <img src={cloud_cyber_security} alt="cloud image" loading='lazy' />
                </div>
            </section>

            <section className="pt-5">
                <div className="container border">
                    <div className="row g-3 my-2 d-flex align-items-center">
                        <div className="col-md-9">
                            <div>
                                <p className="cloud__services__desc__txt">
                                    Zaimah Tech's cloud service offerings are designed to meet the needs of businesses of all sizes, from small startups to large enterprises. They offer a range of cloud services, including:

                                    Infrastructure as a Service (IaaS): Zaimah Tech's IaaS solutions provide businesses with the computing power, storage, and networking resources they need to run their applications and services. Customers can easily scale up or down their infrastructure based on their changing needs, without the need to invest in expensive hardware.

                                    Platform as a Service (PaaS): Zaimah Tech's PaaS solutions enable businesses to develop, deploy, and manage their applications without the need to manage the underlying infrastructure. This enables businesses to focus on developing their applications, while Zaimah Tech takes care of the rest.

                                    Software as a Service (SaaS): Zaimah Tech's SaaS solutions provide businesses with access to a range of software applications, such as productivity tools, customer relationship management (CRM) software, and more. These solutions are delivered over the internet and can be accessed from anywhere, at any time.

                                    Cloud Storage: Zaimah Tech's cloud storage solutions provide businesses with secure, reliable, and scalable storage for their data. This enables businesses to store and access their data from anywhere, while also ensuring that their data is protected from data loss or theft.

                                    Cloud Backup: Zaimah Tech's cloud backup solutions provide businesses with automated backup and recovery of their critical data. This ensures that businesses can recover their data quickly and easily in the event of a data loss or disaster.

                                    In addition to these cloud services, Zaimah Tech also provides customers with a range of tools and services to help them manage their cloud environments. These include monitoring and reporting tools, security and compliance services, and support services.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h6 className="cloud__services__title__txt text-end">CYBER SECURITY SERVICES</h6>
                        </div>
                    </div>
                </div>
                <div className="cloud__services__img pt-5">
                    <img src={cloud_cyber_security} alt="cloud image" loading='lazy' />
                </div>
            </section>

            <section className="pt-5">
                <div className="container border">
                    <div className="row g-3 my-2 d-flex align-items-center">
                        <div className="col-md-3">
                            <h6 className="cloud__services__title__txt">SERVICE LIST OF CLOUD COMPUTING</h6>
                        </div>
                        <div className="col-md-9">
                            <div>
                                <p className="cloud__services__desc__txt">
                                    Zaimah Tech offers a range of services to businesses that are looking to move their IT infrastructure to the cloud. Here are some of the cloud computing services offered by Zaimah Tech:

                                    Infrastructure as a Service (IaaS): IaaS provides businesses with computing infrastructure, such as virtual machines, storage, and networking. Customers can rent this infrastructure on demand, and only pay for what they use.

                                    Platform as a Service (PaaS): PaaS provides businesses with a platform for developing, deploying, and managing their applications. This enables businesses to focus on their application development, while the PaaS provider manages the underlying infrastructure.

                                    Software as a Service (SaaS): SaaS provides businesses with access to software applications, such as customer relationship management (CRM) software, productivity tools, and more. These applications are delivered over the internet and can be accessed from anywhere, at any time.

                                    Database as a Service (DBaaS): DBaaS provides businesses with a database management system, such as MySQL or Oracle, that is hosted in the cloud. This enables businesses to store and manage their data without the need for on-premises hardware.

                                    Disaster Recovery as a Service (DRaaS): DRaaS provides businesses with a disaster recovery solution that is hosted in the cloud. This enables businesses to recover their data and systems in the event of a disaster, such as a cyber attack or natural disaster.

                                    Backup as a Service (BaaS): BaaS provides businesses with a cloud-based backup solution that enables them to backup their data offsite, protecting them from data loss due to hardware failure, cyber attacks, or other disasters.

                                    Content Delivery Network (CDN): CDN provides businesses with a network of servers that are geographically dispersed, enabling them to deliver their content to users more quickly and efficiently.

                                    Function as a Service (FaaS): FaaS provides businesses with a platform for deploying and executing functions in the cloud. This enables businesses to run their applications more efficiently and cost-effectively.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cloud__services__img pt-5">
                    <img src={cloud_service_img} alt="cloud image" loading='lazy' />
                </div>
            </section>

            <section className="pt-5">
                <div className="container border">
                    <div className="row g-3 my-2 d-flex align-items-center">
                        <div className="col-md-9">
                            <div>
                                <p className="cloud__services__desc__txt">

                                    Zaimah Tech is a leading provider of cyber security services, offering businesses a range of solutions to protect their digital assets from cyber threats. With the rise of cyber attacks and data breaches, cyber security has become a critical concern for businesses of all sizes. Zaimah Tech provides businesses with the tools and expertise they need to stay secure and protect their sensitive data.

                                    Zaimah Tech's cyber security services include:

                                    Security Assessment: Zaimah Tech offers security assessments to identify vulnerabilities and weaknesses in a business's IT infrastructure. They use a range of tools and techniques to perform assessments, including penetration testing, vulnerability scans, and risk assessments. The results of these assessments are used to create a roadmap for improving a business's security posture.

                                    Managed Security Services: Zaimah Tech provides businesses with managed security services to monitor their IT infrastructure for security threats around the clock. These services include intrusion detection and prevention, threat monitoring, and incident response. Zaimah Tech's team of security experts work to detect and respond to threats quickly, minimizing the impact of a cyber attack.

                                    Identity and Access Management: Zaimah Tech provides businesses with solutions to manage user access to their IT systems and applications. These solutions include multi-factor authentication, password management, and user access controls. This helps to prevent unauthorized access to sensitive data and reduces the risk of data breaches.

                                    Cybersecurity Training: Zaimah Tech provides cybersecurity training to help businesses educate their employees about cyber threats and best practices for staying secure online. These training sessions include phishing simulations, social engineering awareness, and security awareness training.

                                    Compliance Services: Zaimah Tech provides compliance services to help businesses meet regulatory requirements, such as HIPAA, PCI DSS, and GDPR. Zaimah Tech's team of experts work with businesses to ensure they are meeting all necessary compliance standards, reducing the risk of fines and legal action.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h6 className="cloud__services__title__txt text-end">SERVICE LIST OF CYBER SECURITY</h6>
                        </div>
                    </div>
                </div>
                <div className="cloud__services__img pt-5 mb-5">
                    <img src={cyber_sec_services} alt="cloud image" loading='lazy' />
                </div>
            </section>
        </>
    );
};

export default CloudCyberSecurity;