import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container mb-4">
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">Terms Of Service</h4>
                    <p className='privacy__txt'>
                        Welcome to our website. By accessing or using our website, you agree to comply with these terms and conditions.
                        Please read these terms carefully before using our website.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">1. Use of Website</h4>
                    <p className='privacy__txt'>
                        You may use our website for lawful purposes only. You agree not to use our website for any illegal, harmful, or offensive
                        activities, including but not limited to, the following:
                    </p>
                    <ul style={{ listStyleType: "circle" }} className="privacy__txt__list">
                        <li>Sending spam, chain letters, or unsolicited email;</li>
                        <li>Attempting to interfere with the website’s functionality or security;</li>
                        <li>Uploading or sharing any content that is false, misleading, defamatory, or infringing on any third-party rights;</li>
                        <li>Attempting to gain unauthorized access to any part of the website or its services;</li>
                        <li>Using any automated tool or software to scrape, harvest, or extract data from our website.</li>
                    </ul>
                    <p className='privacy__txt pt-2'>
                        We reserve the right to terminate or restrict your access to our website, without notice or liability, for any reason whatsoever.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">2. Intellectual Property Rights</h4>
                    <p className='privacy__txt'>
                        All content on our website, including text, graphics, logos, images, and software, is the property of our company or its affiliates and is protected by copyright and other intellectual property laws. You agree not to copy, modify, reproduce, distribute, or create derivative works of any content on our website without our prior written consent.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">3. Disclaimer of Warranties</h4>
                    <p className='privacy__txt'>
                        Our website is provided on an “as is” and “as available” basis. We do not guarantee that our website will be uninterrupted, error-free, or free from viruses or other harmful components. We do not warrant the accuracy, completeness, or reliability of any information on our website.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">4. Limitation of Liability</h4>
                    <p className='privacy__txt'>
                        To the fullest extent permitted by law, we shall not be liable for any damages, including but not limited to, direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use our website or its services. You agree to indemnify us and hold us harmless from any claims, damages, or losses arising out of or in connection with your use of our website.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">5. Links to Third-Party Websites</h4>
                    <p className='privacy__txt'>
                        Our website may contain links to third-party websites that are not owned or controlled by our company. We are not responsible for the content or privacy practices of any third-party websites. We encourage you to read the terms and conditions and privacy policies of any third-party websites that you visit.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">6. Changes to Terms and Conditions</h4>
                    <p className='privacy__txt'>
                        We reserve the right to modify these terms and conditions at any time. You agree to be bound by the current version of these terms and conditions each time you access or use our website.
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">7. Governing Law</h4>
                    <p className='privacy__txt'>
                        These terms and conditions shall be governed by and construed in accordance with the laws of [insert governing law]. Any dispute arising out of or in connection with these terms and conditions shall be resolved by the courts of [insert jurisdiction].
                    </p>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12">
                    <h4 className="hd__txt">8. Contact Us</h4>
                    <p className='privacy__txt'>
                        If you have any questions or concerns about these terms and conditions or our website, please contact us.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default TermsOfService;