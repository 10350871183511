import React from 'react';
import under__construction__img3 from "../../Images/under__construction__img3.webp"
const HRMPayrollManagement = () => {
    return (
        <>
        <div className="container pt-4">
        <div className="under__construction__img">
            <img src={under__construction__img3} className="img-fluid" alt="under__construction__img3"  />
        </div>
        </div>
      
    </>
    );
};

export default HRMPayrollManagement;