import React from 'react';

const NotFound = () => {
    return (
        <div className="container pt-5 mb-5">
            <h1 className="text-center">Page Not Found <br/>404</h1>
        </div>
    );
};

export default NotFound;