import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import axios from 'axios';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";


import SocialIcons from './../social_Icons/SocialIcons';
import Character from '../../Images/Character.png';
import Slider from "react-slick";
import GetQuoteButton from '../main_home_page/GetQuoteButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import javascript from '../../Images/javascript.png';
import nodejs from '../../Images/nodejs.png';
import react from '../../Images/react.png';
import angular from '../../Images/angular.png';
import php from '../../Images/php.png';
import laravel from '../../Images/laravel.png';
import mysql from '../../Images/mysql.png';
import fluter from '../../Images/fluter.png';
import Desk from '../../Images/Desk.png';
import Agent from '../../Images/Agent.jpg';
import img__coming__soon from '../../Images/img__coming__soon.jpg';
import web___ux from '../../Images/web___ux.png';
import Google_img from '../../Images/Google_img.png';
import jabber_img from '../../Images/jabber_img.png';
import clutch_img from '../../Images/clutch_img.png';
import TrustPilot_img from '../../Images/TrustPilot_img.png';
import Logo__img1 from '../../Images/Logo__img1.png';
import AyneerGurucul from '../../Images/AyneerGurucul.png';
import BCLC_img from '../../Images/BCLC_img.png';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "white", borderRadius: "100px" }}
            onClick={onClick}
        />
    );
}


const WebDevelopment = () => {

    const WebTechnologiesFrameworksSlider = {
        // className: "center mx-4",
        // infinite: true,
        // centerPadding: "60px",
        // slidesToShow: 5,
        // swipeToSlide: true,
        // adaptiveHeight: true,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
        className: "center",
        dots: true,
        infinite: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    const [webTechFrameworks, setWebTechFrameworks] = useState([
        { technologies_name: "JavaScript", technologies_img: javascript, id: 1 },
        { technologies_name: "Node.js", technologies_img: nodejs, id: 2 },
        { technologies_name: "React.js", technologies_img: react, id: 3 },
        { technologies_name: "Angular.js", technologies_img: angular, id: 4 },
        { technologies_name: "PHP", technologies_img: php, id: 5 },
        { technologies_name: "Laravel", technologies_img: laravel, id: 6 },
        { technologies_name: "MySql", technologies_img: mysql, id: 7 },
        { technologies_name: "Fluter", technologies_img: fluter, id: 8 },
        { technologies_name: "PHP", technologies_img: php, id: 9 },

    ]);
    const [ourRecentWebProjects, setOurRecentWebProjects] = useState([
        { projects_name: "Clinical Management System", id: 1 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 2 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 3 },
        { projects_name: "Automated Legal Contracts Generation", id: 4 },
        { projects_name: "Clinical Management System", id: 5 },
        { projects_name: "E-Commerce Platform for Automotive Products", id: 6 },
        { projects_name: "HR Portal for Job Posting Automatization", id: 7 },
        { projects_name: "Automated Legal Contracts Generation", id: 8 },
    ]);
    const [ourRecentWebUse, setOurRecentWebUse] = useState([
        { technologies_name: "JavaScript", id: 1 },
        { technologies_name: "Node.JS", id: 2 },
        { technologies_name: "React.JS", id: 3 },
        { technologies_name: "PHP", id: 4 },
        { technologies_name: "Laravel", id: 5 },
        { technologies_name: "Fluter", id: 6 },
        { technologies_name: "MySQL", id: 7 },
        { technologies_name: "MongoDB", id: 8 },
    ]);
    // const [recentWebProjects, setRecentWebProjects] = useState([
    //     { id: 1, image: Logo__img1, title_name: "MacroHealthPlus", web_link: "https://macrohealthplus.org/" },
    //     { id: 2, image: AyneerGurucul, title_name: "Ayeener Gurukul", web_link: "http://www.ayneergurukul.com/" },
    //     { id: 3, image: BCLC_img, title_name: "BCLC Project", web_link: "https://dev.bclcbd.com/" },
    // ]);
    // console.log("ourRecentWebUse", ourRecentWebUse);

    const [askQuestion, setAskQuestiondata] = useState([]);
    // console.log("askQuestion", askQuestion);
    const [activePanel, setActivePanel] = useState(1);
    const togglePanel = (panel) => {
        setActivePanel(activePanel === panel ? 0 : panel);
    };


    const [allWeb, setAllWeb] = useState([]);
    const [allWebCard, setAllWebCard] = useState([]);
    const [keyWeb, setkeyWeb] = useState([]);
    const [allWebLogo, setAllWebLogo] = useState([]);
    const [allFaqImage, setAllFaqImage] = useState([]);
    const [allFaq, setallFaq] = useState([]);
    const [allWebProject, setAllWebProject] = useState([]);
    const [recentWebProjects, setRecentWebProjects] = useState([]);

    // console.log('all web check more', allWebProject)

    useEffect(() => {
        axios.get(`/web-development-title-logo`).then(res => {
            if (res.data.status == 200) {
                setAllWeb(res.data.webdev);
            }
        })
        axios.get(`/web-development-card`).then(res => {
            if (res.data.status == 200) {
                setAllWebCard(res.data.web_dev);
            }
        })
        axios.get(`/key-web-technologies`).then(res => {
            if (res.data.status == 200) {
                setkeyWeb(res.data.web);
            }
        })
        axios.get(`/web-technologies-framework-logo`).then(res => {
            if (res.data.status == 200) {
                setAllWebLogo(res.data.web);
            }
        })
        axios.get(`/web-development-faq-image`).then(res => {
            if (res.data.status == 200) {
                setAllFaqImage(res.data.faq_image);
            }
        })
        axios.get(`/web-development-faq`).then(res => {
            if (res.data.status == 200) {
                setallFaq(res.data.faq);
                setAskQuestiondata(res.data.faq);
            }
        })
        axios.get(`/web-development-project`).then(res => {
            if (res.data.status == 200) {
                setAllWebProject(res.data.web_dev);
            }
        })

        axios.get(`/our-web-dev-project`).then(res => {
            // console.log("our_recent_projects", res.data.recent_data);
            if (res.data.status == 200) {
                setRecentWebProjects(res.data.recent_data);
            }
        })
    }, []);




    return (
        <>
            {/* <div className="ht_banner">
                <div className="ht_banner__img">
                    <img src={Agent} alt="image" />
                </div>
                <div className="ht_banner__text">
                    <h3 className="ht_banner__title">Web Development</h3>
                </div>
            </div> */}
            <div className="container mb-5 pt-5">
                <SocialIcons />

                {
                    allWeb.map((item, i) => {
                        var text2 = " ";
                        {
                            var x = item.title_name.split(" ");
                            for (let i = 1; i < x.length; i++) {
                                text2 += x[i] + " ";
                            }
                            // console.log('text2', text2)
                            var text1 = item.title_name.split(" ")[0];
                        }

                        return (

                            <>
                                <div className="row g-3 d-flex align-items-center">
                                    <div className="col-md-7 pe-md-5">
                                        <div className="">
                                            <h1 className="header-web-development">{text1} <span className="header-soft">{text2}</span></h1>
                                            {/* <h1 className="header-web-development"> {item.title_name}</h1> */}
                                            <div className="pt-4">
                                                <GetQuoteButton />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 ">
                                        {/* <div className="character__img"> */}
                                        <div className="">
                                            <img src={`${global.img_url}/images/webdevcare/${item.image}`} className="img-fluid" alt="Character" border="0" />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
                {/* <div className="row g-3">
                    <div className="col-md-7 pe-md-5">
                      <div className="header__dev">
                            <h1 className="header-web-development">Web <span className="header-soft">Development</span></h1>
                            <h1 className="header-web-development"> Services</h1>
                            <div className="web-development-btn">
                                <a src="#" type="text" className="btn btn-get-quate">Get a Quote</a>
                            </div>
                      </div>
                    </div>
                    <div className="col-md-5 ">
                        <div className="character__img">
                            <img src={Character} className="img-fluid" alt="Character" border="0" />
                        </div>
                    </div>
                </div> */}
                <div className="row g-3 pt-5">
                    {
                        allWebCard.map((item, i) => {
                            return (
                                <>
                                    <div className="col-md-6">
                                        <div className="web__development__card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <img src={web___ux} alt="image" style={{ width: "60px", height: "60px" }} />
                                                        <h6 className="web-development-card-title ps-md-4">{item.title}</h6>
                                                    </div>
                                                    <hr className="web-development-hr" />
                                                    <p className="web__development__card__desc">
                                                        {item.short_description}
                                                    </p>
                                                    {/* <div className="backend-development-btn">
                                                        <a src="#" type="text" className="btn-learn-more">
                                                            <span>Learn More</span>
                                                            <span> <i className="fa-solid fa-arrow-right-long"></i></span>
                                                        </a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        })
                    }

                </div>

                <div className="row g-3 pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">Key Web Technologies and Frameworks</h3>
                        {/* <h3 className="header-key-web-technology">Frameworks</h3> */}
                        {
                            keyWeb.map((item, i) => {
                                return (
                                    <>
                                        <p className="key-web-technology-description">
                                            {item.short_description}
                                        </p>

                                    </>
                                )
                            })
                        }

                    </div>
                </div>
                <div className="row g-3 pt-4">
                    <div className="col-12">
                        <div className="web__carousel">
                            <Slider {...WebTechnologiesFrameworksSlider}>
                                {
                                    allWebLogo.length > 0 && allWebLogo.map((item, id) => {
                                        return (
                                            <div key={id} className="web__tech__carousel__box__card">
                                                <div className="card text-center">
                                                    <div className="web__tech__carousel">
                                                        <img src={`${global.img_url}/images/our_partners/${item.image}`} className="img-fluid" alt="..." loading='lazy' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="row g-3 pt-5">
                    <div className="col-12">
                        <h3 className="header-key-web-technology">Our Recent Web Projects</h3>
                        <div className="row g-3 gx-md-5 pt-3">
                            {
                                recentWebProjects.length > 0 && recentWebProjects.map((item, id) => {
                                    return (
                                        <>
                                            <div key={id} className="col-sm-4">
                                                <a href={`${item.web_link}`} target='_blank' className="text-decoration-none">
                                                    <div className="card__web__projects__box__card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5 className="card__web__projects__card__title text-center">{item.heading_name}</h5>
                                                                <div className="card__recent__web__projects__img pt-2">
                                                                    <img src={`${global.img_url}/images/our_recent_projects/${item.image}`} alt="" loading='lazy' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </>
                                    )
                                })
                            }
                            {/* {
                                allWebProject.length > 0 && allWebProject.map((item, id) => {
                                    return (
                                        <>
                                            <div key={id} className="col-sm-4">
                                                <div className="card__web__projects__box__card">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card__web__projects__card__title">{item.project_name}</h5>
                                                            <hr class="web-development-hr"></hr>
                                                            <div className="card__recent__web__projects__text pt-2">
                                                                <div dangerouslySetInnerHTML={{ __html: item.technologies }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            } */}
                        </div>
                    </div>
                </div>
                <div className="row g-3 pt-5 mb-3">
                    <div className="col-md-12 text-center">
                        <Link to="/products" type="text" className="btn btn-discover-all-services">Discover More Projects</Link>
                    </div>
                </div>
                <div className="row g-3 pt-5">
                    <h3 className="header-key-web-technology">FAQs</h3>
                    <div className="col-md-6">
                        <p className="key-web-technology-description">
                            Starting a new project and have a few questions? Some of the most frequently asked questions
                            and answers about custom web application development are listed below.
                        </p>
                      
                        <div className="pt-3">
                            {
                                askQuestion.map((panel, i) => (
                                    <div key={i} className="card mb-3">
                                        <div
                                            className="card-header"
                                            id={`heading${panel.id}`}
                                            onClick={() => togglePanel(panel.id)}
                                        >
                                            <div className="question__ask d-flex justify-content-between">
                                                <p className="ask_qustion__txt">{panel.faq_qtn}</p>
                                                <p className="ask_qustion__icon">
                                                    <FontAwesomeIcon
                                                        // icon={activePanel === panel.id ? faCaretUp : faCaretDown}
                                                        icon={activePanel === panel.id ? faMinus : faPlus}
                                                        className="pull-right me-2"
                                                    />
                                                </p>
                                            </div>
                                        </div>

                                        <div className={`collapse ${activePanel === panel.id ? "show" : ""}`}>
                                            <div className="card-body">
                                                <p className="ask_qustion__content__txt">{panel.faq_ans}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        {
                            allFaqImage.map((item, i) => {
                                return (
                                    <>
                                        <div className="faq__img">
                                            <img src={`${global.img_url}/images/our_partners/${item.image}`} className="img-fluid" alt="Desk" border="0" />
                                        </div>

                                    </>

                                )
                            })
                        }

                    </div>
                </div>


            </div>
        </>
    );
};

export default WebDevelopment;