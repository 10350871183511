import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Swal from 'sweetalert2';


const GetQuoteButton = () => {
    const [isChecked, setIsChecked] = useState(false);

    const [modalConsultationIsOpen, setModalConsultationIsOpen] = useState(false);
    function openModalConsultation(e) {
        e.preventDefault();
        // alert("mmm")
        setModalConsultationIsOpen(true);
    }
    function closeModalConsultation(e) {
        setModalConsultationIsOpen(false);

    }

    const [contact, setContact] = useState({
        'name': '',
        'email': '',
        'phone': '',
        'message': '',
        "isChecked": isChecked,
    })
    const handleChange = (e) => {
        setContact({
            ...contact, [e.target.name]: e.target.value
        });
    }
    function handleCheckboxChange(event) {
        const checked = event.target.checked;
        setIsChecked(checked);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('state data checking', contact)
        if (contact.name == '' || contact.phone == '' || contact.message == '') {
            Swal.fire("Please fill up all inputs", '', 'error')
        }

        else {
            axios.post(`/save-contact-us`, contact).then(res => {
                if (res.data.status == 200) {
                    Swal.fire(res.data.message, '', 'success')

                    setContact({
                        'name': '',
                        'email': '',
                        'phone': '',
                        'message': '',

                    })
                    setModalConsultationIsOpen(false);
                }
                else if (res.data.status == 400) {
                    setContact({ ...contact, error_list: res.data.errors });

                }
            })
        }
    };



    return (
        <>
            <div className="pt-0">
                <button type="button" className="btn-get-start"
                    onClick={openModalConsultation}
                >Get Quote</button>
            </div>
            <Modal
                isOpen={modalConsultationIsOpen}
                onRequestClose={closeModalConsultation}
                // style={customStyles1}
                className="mymodal"
                overlayClassName="myoverlay"
                contentLabel="Example Modal"
            >
                <div className='card-body modal__body'>
                    <div className="row">
                        <div className="col-12">
                            <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalConsultation}><i className="fa-solid fa-xmark close_btn"></i></span>
                            <h6 className="header__consultation__text">Get Quote</h6>
                        </div>
                    </div>
                    <form className="row g-3 pt-1"
                        onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <input type="text" className="form-control form-control-sm" placeholder="Your Name" aria-label="Full Name" name="name" value={contact.name} onChange={handleChange} />
                        </div>

                        <div className="col-md-12">
                            <input type="text" className="form-control form-control-sm" placeholder="Email Address" aria-label="Email address" name="email" value={contact.email} onChange={handleChange} required />
                        </div>
                        <div className="col-md-12">
                            <input type="text" className="form-control form-control-sm" placeholder="Phone No" aria-label="Phone No" name="phone" value={contact.phone} onChange={handleChange} />
                        </div>
                        <div className="mb-3">
                            <textarea className="form-control form-control-sm" id="exampleFormControlTextarea1" placeholder="Write your project description" rows="4" name="message" value={contact.message} onChange={handleChange}></textarea>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" 
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label">
                                    <span className="text-muted">I agree to the</span>
                                    <Link to="/privacy" className="text-decoration-none" ><span className="text_color"> Privacy Policy</span></Link>
                                    <span className="text-muted"> and</span>
                                    <Link to="/terms" className="text-decoration-none" ><span className="text_color"> Terms of Service.</span></Link>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-start pt-4 mr-0">
                                    {
                                        !contact.name == "" && !contact.email == "" && !contact.phone == "" && !contact.message == "" && isChecked ? <button type="submit" className="btn__apply">Submit</button> : <button type="submit" className="btn__apply__disable" disabled>Submit</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

        </>
    );
};

export default GetQuoteButton;